import BRAND_TYPE from 'constants/BRAND_TYPE';
import {
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  required,
} from 'react-admin';

const ChannelCreate = (props) => (
  <Create {...props} redirect={false}>
    <SimpleForm>
      <TextInput source='url' validate={[required()]} />
      <TextInput source='description' />
      <SelectInput
        source='brand_type'
        choices={BRAND_TYPE}
        validate={[required()]}
      />
      <TextInput source='email' />
    </SimpleForm>
  </Create>
);

export default ChannelCreate;
