import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import LabelStatistics from './label';

const SectionLabel = ({ panelTotal, generalTotal }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    if(!panelTotal && !generalTotal) return <></>;

    return (
        <Box
            className="shadow-statistics list-label-statistics"
            mt="20px"
            display="flex"
            p="20px 20px 40px"
        >
            <Grid container spacing={isMobile ? 6 : 2}>
                {panelTotal && (
                    <Grid item xs={12} lg={4}>
                        <LabelStatistics
                            price={panelTotal.price}
                            title="Total Panel"
                            total={panelTotal.total}
                            icon={<BarChartIcon style={{ fontSize: '40px' }} />}
                            panelSpending={panelTotal.charge}
                            revenue={panelTotal.price}
                        />
                    </Grid>
                )}
                {generalTotal && (
                    <Grid item xs={12} lg={4}>
                        <LabelStatistics
                            price={generalTotal.price}
                            title="Total General"
                            total={generalTotal.total}
                            icon={<BarChartIcon style={{ fontSize: '40px' }} />}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    )
};

export default SectionLabel;
