import PlanCreate from './Create';
import PlanEdit from './Edit ';
import PlanList from './List';

const Plan = {
    list: PlanList,
    edit: PlanEdit,
    create: PlanCreate,
};

export default Plan;
