import React, { useCallback } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    FunctionField,
    DateInput,
    Filter,
    SelectInput,
    EditButton,
    TextInput,
    useNotify,
    useDataProvider,
} from 'react-admin';
// import Button from '@material-ui/core/Button';

import { startCase } from 'lodash';
import { Button, makeStyles } from '@material-ui/core';

import APP_CONFIG from 'constants/APP_CONFIG';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});
const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" placeholder="Email" type="email" alwaysOn />
        <TextInput
            source="order_number"
            placeholder="Order number"
            type="text"
            alwaysOn
        />
        <TextInput
            source="order_summary_number"
            placeholder="Order Summary Number"
            type="text"
            alwaysOn
        />
        <TextInput
            source="order_summary_ids"
            placeholder="Order Summary"
            type="text"
            alwaysOn
        />
        <SelectInput
            source="action_type"
            label="Action"
            choices={APP_CONFIG.ORDERS.FILTERS.action_type}
            // alwaysOn
        />
        <SelectInput
            source="status"
            label="Status"
            choices={APP_CONFIG.ORDERS.FILTERS.status}
            // alwaysOn
        />
        <SelectInput
            source="brand_type"
            label="Brand"
            choices={APP_CONFIG.ORDERS.FILTERS.brand_type}
            // alwaysOn
        />
        <SelectInput
            source="smm_status"
            label="SMM Status"
            choices={APP_CONFIG.ORDERS.FILTERS.smm_status}
            // alwaysOn
        />

        <DateInput source="from_date" label="From Date" />
        <DateInput source="to_date" label="End Date" />
    </Filter>
);

const ButtonCustom = (props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            data-id={props.record.id}
            onClick={props.onClick}
        >
            Initital SMM
        </Button>
    );
};
const OrderList = (props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const onClickRenew = useCallback(
        async (evt) => {
            evt.preventDefault();
            const { id } = evt.currentTarget.dataset;
            try {
                const { data } = await dataProvider.renewOrder(`orders/`, {
                    data: {},
                    id,
                });
                if (data && data.succeed) {
                    notify('Initital SMM successful');
                } else {
                    notify('Initital SMM failed', 'error');
                }
            } catch (e) {
                notify('Initital SMM failed', 'error');
            }
        },
        [dataProvider, notify],
    );
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="List of orders"
            filters={<PostFilter />}
            sort={{ field: 'order_by', order: 'desc' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="order_number" label="Order Number" />
                <TextField source="url" label="URL" />
                <FunctionField
                    label="Action Type"
                    render={(record) =>
                        `${startCase(record.action_type.replace(/_/g, ' '))}`
                    }
                />
                <NumberField source="delivered" label="Delivered" />
                <TextField source="desc" label="Description" />
                <TextField source="order_summary_id" label="Order Summary ID" />
                <NumberField source="price" label="Price" />
                <NumberField source="quantity" label="Quantity" />
                <NumberField source="retry" label="Retry" />
                <NumberField
                    source="payer_email_address"
                    label="Payer email address"
                />
                <FunctionField
                    label="SMM Order Id"
                    textAlign="right"
                    render={(record) =>
                        record.smm_order_id ? record.smm_order_id : '-'
                    }
                />
                <FunctionField
                    label="SMM Service Name"
                    textAlign="right"
                    render={(record) =>
                        record.smm_service_name
                            ? startCase(
                                  record.smm_service_name.replace(/_/g, ' '),
                              )
                            : '-'
                    }
                />
                <FunctionField
                    label="SMM Service Status"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(
                            record.smm_service_status.replace(/_/g, ' '),
                        )}`
                    }
                />
                <NumberField source="start_count" label="Start Count" />
                <FunctionField
                    label="Status"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(record.status.replace(/_/g, ' '))}`
                    }
                />
                <DateField source="created_at" label="Created At" />
                <EditButton />
                <ButtonCustom onClick={onClickRenew} />
            </Datagrid>
        </List>
    );
};

export default OrderList;
