import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    DeleteWithConfirmButton,
    TextInput,
    Filter,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    row: {
        whiteSpace: 'nowrap',
    },
    header: {
        background: '#ccc',
        whiteSpace: 'nowrap',
    },
});

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" placeholder="Email" type="email" alwaysOn />
        <TextInput source="id" placeholder="Channel ID" type="text" alwaysOn />
        <TextInput
            source="brand_type"
            placeholder="Brand type"
            type="text"
            alwaysOn
        />
    </Filter>
);

const ChannelList = (props) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="Channel"
            filters={<PostFilter />}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="id" label="Channel ID" />
                <TextField source="url" label="URL" />
                <TextField source="email" label="Email" />
                <TextField source="brand_type" label="Brand Type" />
                <TextField source="description" label="Description" />
                <DateField source="created_at" label="Created At" />
                <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
            </Datagrid>
        </List>
    );
};

export default ChannelList;
