export function getDataStatistics(dataList) {
    const brandTypes = dataList.map((item) => item.brand_type);
    const data = dataList.map((item) => ({
        name: item.brand_type,
        value: item.total,
    }));
    const dataStatus = dataList.reduce((acc, item) => {
        const { brand_type, data, ...rest } = item;
        acc[brand_type] = rest;
        return acc;
    }, {});

    return {
        brandTypes,
        data,
        dataStatus,
    };
}

export function getDataStatisticsItems(dataList) {
    const actionType = dataList.map((item) => item.action_type);

    const data = dataList.map((item) => ({
        name: item.action_type,
        value: item.total,
    }));

    const totalStatus = (() => {
        const result = {};
        dataList.forEach((item) => {
            item.data.forEach((subItem) => {
                const { status, total } = subItem;
                result[status] = result[status]
                    ? result[status] + total
                    : total;
            });
        });
        return result;
    })();

    return {
        actionType,
        data,
        totalStatus,
    };
}

export function getDataStatisticsActionType(dataList) {
    const status = dataList.map((item) => item.status);
    const data = dataList.map((item) => ({
        name: item.status,
        value: item.total,
    }));
    const dataStatus = dataList.reduce((acc, item) => {
        const { status, ...rest } = item;
        acc[status] = rest;
        return acc;
    }, {});

    return {
        status,
        data,
        dataStatus,
    };
}

export const formatNumber = (number) => {
    return number.toLocaleString();
};
