import { DOMAINS } from 'constants/APP_CONFIG';
import BRAND_TYPE from 'constants/BRAND_TYPE';
import {
    Create,
    SimpleForm,
    SelectInput,
    required,
    NumberInput,
    minValue,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

const CouponCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ArrayInput source="discount">
                <SimpleFormIterator>
                    <NumberInput
                        source="amount"
                        label="Reach value to get offer"
                        validate={[required(), minValue(0)]}
                        parse={(v) => parseInt(v)}
                    />
                    <NumberInput
                        source="percent"
                        label="Percent"
                        validate={[required(), minValue(0)]}
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <SelectInput source="domain" defaultValue="" choices={DOMAINS} />

            <SelectInput source="brand_type" choices={BRAND_TYPE} />
        </SimpleForm>
    </Create>
);

export default CouponCreate;
