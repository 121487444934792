import { Box, Grid } from '@material-ui/core';
import { COLORS } from 'constants/APP_CONFIG';

const BoxItemColor = ({
    data,
    sx = 12,
    md = 6,
    gap,
    sizeBox = '20px',
    radius = '5px',
}) => (
    <Grid container spacing={1}>
        {data.map((type, idx) => (
            <Grid key={`brand_type_${idx}`} item xs={sx} md={md}>
                <Box display="flex" alignItems="center" gridGap={gap}>
                    <Box
                        height={sizeBox}
                        width={sizeBox}
                        bgcolor={COLORS[idx % COLORS.length]}
                        borderRadius={radius}
                    />
                    <p style={{ textTransform: 'uppercase' }} title={type}>
                        {type}
                    </p>
                </Box>
            </Grid>
        ))}
    </Grid>
);

export default BoxItemColor;
