import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Switch, TextField } from "@material-ui/core";
import {
  useDataProvider,
  Loading,
  Error,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  customForm: {
    display: "flex",
    "flex-direction": "column",
  },
  buttonGroup: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const PrivateEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const history = useHistory();
  const { panelId, id } = props.match.params;
  const dataPanelService = useSelector(
    (state) => state.admin.resources["smm/"]?.data[panelId]
  );

  let currentServices = dataPanelService?.services.filter(
    (service) => `${service.id}` === `${id}`
  )[0];

  useEffect(() => {
    if (typeof currentServices === "undefined") {
      redirect(`/smm//${panelId}/show`);
    }
  }, [currentServices, history, panelId, redirect]);

  const [services, setServices] = useState(currentServices);

  const [isEnable, setIsEnabled] = useState(services?.enabled);
  const handleSwitch = useCallback(() => {
    setIsEnabled(!isEnable);
  }, [isEnable]);

  const [payload, setPayload] = useState(services);

  const onChange = useCallback((event) => {
    const { id, value } = event.target;
    setPayload((prevState) => ({ ...prevState, [id]: value }));
  }, []);

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onSubmit = useCallback(() => {
    setLoading(true);
    const { brand_type, action_type, service_id } = payload;
    dataProvider
      .update(`smm/${id}`, {
        service: {
          brand_type,
          action_type,
          enabled: isEnable,
          service_id,
        },
      })
      .then(({ data }) => {
        setServices(data.services);
        setLoading(false);
        notify("Update services succeed.");
        redirect(`/smm//${panelId}/show`);
        refresh();
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [isEnable, payload, panelId, redirect, dataProvider, id, refresh, notify]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.customForm}>
        <TextField
          type="number"
          disabled
          id="service_id"
          label="Service Id"
          defaultValue={services?.service_id}
          onChange={onChange}
        />
        <TextField
          required
          id="brand_type"
          label="Brand Type"
          defaultValue={services?.brand_type}
          onChange={onChange}
        />
        <TextField
          required
          id="action_type"
          label="Action Type"
          defaultValue={services?.action_type}
          onChange={onChange}
        />
        <TextField
          type="number"
          id="rate"
          label="Rate"
          defaultValue={services?.rate}
          onChange={onChange}
        />
        <TextField
          type="number"
          id="minimum"
          label="Minimum"
          defaultValue={services?.minimum}
          onChange={onChange}
        />
        <TextField
          type="number"
          id="maximum"
          label="Maximum"
          defaultValue={services?.maximum}
          onChange={onChange}
        />
        <TextField
          id="desc"
          label="Description"
          defaultValue={services?.desc}
          onChange={onChange}
        />
        <Switch
          checked={isEnable}
          onChange={handleSwitch}
          name="enabled"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
      <div className={classes.buttonGroup}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={loading}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.history.back()}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default PrivateEdit;
