import { apis } from "../helpers/instanceApi";

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const response = await apis.post("/token", formData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    localStorage.setItem("access_token", response.access_token);
    // accept all username/password combinations
    return Promise.resolve({ redirect: "orders/" });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("access_token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("access_token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("access_token")
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),

  getAccessToken: () => localStorage.getItem("access_token"),
};

export default authProvider;
