import { Box } from '@material-ui/core';
import { formatNumber } from 'helpers/statistics';

const LabelStatistics = ({
    title,
    price,
    total,
    icon,
    panelSpending,
    revenue,
}) => (
    <Box
        borderRadius={5}
        p="30px"
        minHeight="74px"
        position="relative"
        className="item-label"
        boxShadow="rgb(190, 190, 190) 0px 0px 5px"
    >
        <Box display="flex" gridGap="10px" alignItems="center" mb="10px">
            <h4 style={{ fontSize: '14px' }}>{title}</h4>
            {(total || price) && (
                <>
                    <span
                        style={{
                            width: '1px',
                            height: '20px',
                            backgroundColor: '#000',
                        }}
                    />
                    <h3
                        style={{
                            fontSize: '28px',
                        }}
                    >
                        {formatNumber(total)}
                    </h3>
                    {!!price && (
                        <>
                            <span
                                style={{
                                    width: '1px',
                                    height: '20px',
                                    backgroundColor: '#000',
                                }}
                            />
                            <h3
                                style={{
                                    fontSize: '28px',
                                }}
                            >
                                {formatNumber(price)}
                            </h3>
                        </>
                    )}
                </>
            )}
        </Box>
        {!!panelSpending && (
            <h4 style={{ marginBottom: '10px' }}>
                Panel Spendings: {formatNumber(panelSpending)}
            </h4>
        )}
        {!!revenue && (
            <h4 style={{ marginBottom: '10px' }}>
                Revenue: {formatNumber(revenue)}
            </h4>
        )}
        {!!revenue && !!panelSpending && (
            <h4 style={{ marginBottom: '10px' }}>
                Gross profit: {formatNumber(revenue - panelSpending)}
            </h4>
        )}
        <Box
            color="#fff"
            width="60px"
            height="60px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="calc(100% - 30px)"
            left="10px"
            borderRadius="50%"
            border="2px solid #fff"
            style={{
                backgroundImage: 'linear-gradient(to right, #000 , #231f9d)',
            }}
        >
            {icon}
        </Box>
        <Box color="#534dac" position="absolute" right="20px" bottom="20px">
            {icon}
        </Box>
    </Box>
);

export default LabelStatistics;
