const BRAND_TYPE = [
  {
    name: 'Youtube',
    id: 'youtube',
  },
  {
    name: 'Tiktok',
    id: 'tiktok',
  },
  {
    name: 'Facebook',
    id: 'facebook',
  },
  {
    name: 'Instagram',
    id: 'instagram',
  },
  {
    name: 'Twitter',
    id: 'twitter',
  },
  {
    name: 'Spotify',
    id: 'spotify',
  },
  {
    name: 'Soundcloud',
    id: 'soundcloud',
  },
  {
    name: 'Twitch',
    id: 'twitch',
  },
];
export default BRAND_TYPE;