import {
    Create,
    TextInput,
    SimpleForm,
    required,
    NumberInput,
    BooleanInput,
    minValue,
    SelectInput,
} from 'react-admin';

import { PLAN_TYPE, STATUS } from 'constants/PLAN_TYPE';

const PlanCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <SelectInput
                source="type"
                choices={PLAN_TYPE}
                validate={[required()]}
            />
            <TextInput source="description" validate={[required()]} />
            <NumberInput
                source="popular_price"
                validate={[required(), minValue(1)]}
            />
            <NumberInput
                source="alternative_price"
                validate={[required(), minValue(1)]}
            />
            <SelectInput
                source="status"
                choices={STATUS}
                validate={[required()]}
            />
            <NumberInput
                source="priority"
                validate={[required(), minValue(0)]}
            />
            <BooleanInput label="Is popular" source="is_popular" />
        </SimpleForm>
    </Create>
);

export default PlanCreate;
