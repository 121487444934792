import PackageCreate from './Create';
import PackageEdit from './Edit ';
import PackageList from './List';

const Package = {
    list: PackageList,
    edit: PackageEdit,
    create: PackageCreate,
};

export default Package;
