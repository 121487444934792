import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import BoxItemColor from 'components/Dashboard/components/box-color';
import StatusOrderChart from 'components/Dashboard/components/chart';
import ListInfoItem from 'components/Dashboard/components/list-info-item';
import { getDataStatisticsActionType } from 'helpers/statistics';

const ListStatisticsActionType = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            borderTop="1px solid rgba(128, 128, 128, 0.336)"
            pt="10px"
            mt="10px"
        >
            <Grid container spacing={1}>
                {data.map((itemData, idx) => {
                    const {
                        status,
                        data: dataActionType,
                        dataStatus = {},
                    } = getDataStatisticsActionType(itemData?.data || []);
                    return (
                        <Grid
                            key={`${itemData.action_type}-${idx}`}
                            item
                            xs={6}
                            md={4}
                        >
                            <Box
                                boxShadow="0 0 5px #bebebe"
                                p="10px"
                                className="item-action-type"
                            >
                                <h3 title={itemData.action_type}>
                                    {itemData.action_type}
                                </h3>
                                <Box
                                    display="flex"
                                    gridGap={4}
                                    alignItems="flex-start"
                                >
                                    <Box width="80px" height="80px">
                                        <StatusOrderChart
                                            data={dataActionType}
                                            height={90}
                                            width={90}
                                            cx={35}
                                            cy={35}
                                            outer={40}
                                            inner={30}
                                        />
                                    </Box>
                                    <Box width="calc(100% - 80px - 4px)">
                                        <BoxItemColor
                                            data={status}
                                            gap={isMobile ? 2 : 4}
                                            md={12}
                                            sx={12}
                                            sizeBox={isMobile ? '5px' : '10px'}
                                            radius="2px"
                                        />
                                    </Box>
                                </Box>
                                <ListInfoItem data={dataStatus} />
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};
export default ListStatisticsActionType;
