import CouponCreate from './Create';
import OrderList from './List';
import CouponEdit from './Edit';

const Discounts = {
    list: OrderList,
    create: CouponCreate,
    edit: CouponEdit,
};

export default Discounts;
