import {
  TextInput,
  NumberInput,
  BooleanInput,
  Edit,
  SimpleFormIterator,
  ArrayInput,
  required,
  SimpleForm,
  SelectInput,
} from "react-admin";

import APP_CONFIG, { SMM_SERVICE_STATUS } from "constants/APP_CONFIG";

const ServicesInSMMList = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} label="Name" />
        <TextInput source="desc" validate={required()} label="Description" />
        <TextInput source="api_url" validate={required()} label="API URL" />
        <TextInput source="api_key" validate={required()} label="API Key" />
        <SelectInput
          source="status"
          choices={SMM_SERVICE_STATUS}
          label="Status"
          validate={required()}
        />
        <ArrayInput source="services">
          <SimpleFormIterator inline>
            <TextInput
              source="desc"
              label="Description"
              validate={required()}
            />
            <TextInput
              source="service_id"
              label="Service ID"
              validate={required()}
            />
            <SelectInput
              source="brand_type"
              choices={APP_CONFIG.ORDERS.FILTERS.brand_type}
              label="Brand Type"
              validate={required()}
            />
            <SelectInput
              source="action_type"
              label="Action Type"
              choices={APP_CONFIG.ORDERS.FILTERS.action_type}
              validate={required()}
            />
            <NumberInput
              source="maximum"
              min={0}
              label="Maximum"
              validate={required()}
            />
            <NumberInput
              source="minimum"
              min={0}
              label="Mininum"
              validate={required()}
            />
            <NumberInput
              source="rate"
              min={0}
              label="Rate"
              validate={required()}
            />

            <BooleanInput source="enabled" label="Enabled" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default ServicesInSMMList;
