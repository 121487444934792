import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { formatNumber } from 'helpers/statistics';

const ListInfoItem = ({ data, xs = 12, lg = 6 }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box mt="10px" className="list-item-status" width='100%'>
            <Grid container spacing={isMobile ? 1 : 2}>
                {!!Object.keys(data).length &&
                    Object.keys(data).map((status, index) => (
                        <Grid
                            key={`item-info-${index}`}
                            container
                            item
                            xs={xs}
                            lg={lg}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <h4
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                {status}:
                            </h4>
                            <Box className="box-info-item">
                                {Object.keys(data[status]).map((s, i) => (
                                    <p key={`box-info-item-${i}`}>
                                        {s}: {formatNumber(data[status][s])}
                                    </p>
                                ))}
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default ListInfoItem;
