import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    DeleteWithConfirmButton,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const PlanList = (props) => {
    const classes = useStyles();
    return (
        <List {...props} title="Plan list">
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="name" />
                <TextField source="description" />
                <TextField source="popular_price" />
                <TextField source="alternative_price" />
                <TextField source="priority" />
                <BooleanField source="is_popular" />
                <TextField source="status" label="Stattus" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default PlanList;
