import React, { useCallback } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    SelectInput,
    TextInput,
    Filter,
    FunctionField,
    DateField,
    useDataProvider,
    useNotify,
} from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import APP_CONFIG from 'constants/APP_CONFIG';
import { startCase } from 'lodash';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});
const ArraySimpleField = ({ record, source }) => {
    const array = record[source];
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div />;
    }
    return (
        <>
            {array.map((item) => (
                <p key={item}>{item}</p>
            ))}
        </>
    );
};
ArraySimpleField.defaultProps = { addLabel: true };

const CustomURLField = ({ record, source }) => {
    if (!record) return null;
    return (
        <a
            href={`#/orders/?filter=%7B"order_summary_ids"%3A"${record[source]}"%7D&order=desc&page=1&perPage=10&sort=order_by`}
        >
            {record[source]}
        </a>
    );
};
const ButtonCustom = (props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            data-id={props.record.id}
            onClick={props.onClick}
        >
            Initital SMM
        </Button>
    );
};

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" placeholder="Email" type="email" alwaysOn />
        <TextInput
            source="order_summary_number"
            placeholder="Order summmary number"
            type="text"
            alwaysOn
        />
        <TextInput
            source="order_number"
            placeholder="Panel Order ID"
            type="text"
            alwaysOn
        />
        <TextInput
            source="order_summary_id"
            placeholder="Order Summary ID"
            type="text"
            alwaysOn
        />
        <SelectInput
            source="action_type"
            label="Action"
            choices={APP_CONFIG.SUBSCRIPTION.action_type}
            alwaysOn
        />
        <SelectInput
            source="status"
            label="Status"
            choices={APP_CONFIG.SUBSCRIPTION.status}
            alwaysOn
        />
        <SelectInput
            source="brand_type"
            label="Brand"
            choices={APP_CONFIG.SUBSCRIPTION.brand_type}
            alwaysOn
        />
    </Filter>
);
const SubscriptionList = (props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const onClickRenew = useCallback(
        async (evt) => {
            evt.preventDefault();
            const { id } = evt.currentTarget.dataset;
            try {
                const { data } = await dataProvider.renewOrder(
                    `subscription/panel/`,
                    {
                        data: {},
                        id,
                    },
                );
                if (data && data.success) {
                    notify('Initital SMM successful');
                } else {
                    notify('Initital SMM failed', 'error');
                }
            } catch (e) {
                notify('Initital SMM failed', 'error');
            }
        },
        [dataProvider, notify],
    );
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="List of Subscription"
            filters={<PostFilter />}
            sort={{ field: 'order_by', order: 'desc' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="id" label="ID" />
                <TextField source="url" label="URL" />
                <TextField source="email" label="Email" />
                <NumberField source="price" label="Price" />
                <FunctionField
                    label="Status"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(record.status.replace(/_/g, ' '))}`
                    }
                />
                <FunctionField
                    label="Brand Type"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(record.brand_type.replace(/_/g, ' '))}`
                    }
                />
                <FunctionField
                    label="Action Type"
                    render={(record) =>
                        `${startCase(record.action_type.replace(/_/g, ' '))}`
                    }
                />
                <TextField source="smm_order_id" label="Smm Order Id" />
                <CustomURLField
                    source="order_summary_id"
                    label="Order Summary Id"
                />
                <ArraySimpleField source="smm_orders" label="Smm Orders" />
                <NumberField source="new_posts" label="New Posts" />
                <NumberField source="old_posts" label="OldPosts" />
                <NumberField source="current_posts" label="Current Posts" />
                <NumberField source="delay" label="Delay" />
                <DateField source="expiry" label="Expiry" />
                <NumberField source="minimum" label="Minimum" />
                <NumberField source="maximum" label="Maximum" />
                <DateField source="created_at" label="Created At" />
                <DateField source="updated_at" label="Updated At" />
                <ButtonCustom onClick={onClickRenew} />
            </Datagrid>
        </List>
    );
};

export default SubscriptionList;
