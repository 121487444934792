import axios from 'axios';
import qs from 'qs';
import authProvider from 'auth/authProvider';
// import RESOURCE from "constants/Resource";
import { serverErrorDataToString } from './generalHelper';
import { SENTRY_NON_CATCH_APIS } from 'constants/SENTRY_CONFIG';

const prefixAPI = '/admin/';

function getUrl(resource, id) {
    if (!id) {
        return `${prefixAPI}${resource}`;
    }
    if (resource[resource.length - 1] !== '/') {
        return `${prefixAPI}${resource}/${id}`;
    }
    return `${prefixAPI}${resource}${id}`;
}

export const apis = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: false,
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    },
});

apis.interceptors.request.use((config) => {
    const newConfig = config;
    const token = authProvider.getAccessToken();
    newConfig.headers = { Authorization: `Bearer ${token}` };
    return newConfig;
});

apis.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        // add key remove warning: Missing translation for key
        const { status, data } = error.response;
        let message = '';

        message = serverErrorDataToString(data);
        if (status === 401 || status === 403) {
            authProvider.logout();
            return Promise.reject(error.response);
        }

        const apiEndpoint = (error.config && error.config.url) || '';

        const checkNonCatchSentry = SENTRY_NON_CATCH_APIS.some((val) =>
            apiEndpoint.includes(val),
        );

        if (checkNonCatchSentry || status !== 500) {
            return Promise.reject(message);
        }

        if (apiEndpoint !== '')
            message += `\nAPI Endpoint: ${error.config.url}`;

        Promise.reject(new Error(message));
        throw new Error(message);
    },
);

export const dataProvider = {
    getList: async (resource, { ...props }) => {
        const PUBLIC_RESOURCES = ['package', 'plan', 'discount'];
        const { perPage, page } = props.pagination || {};
        let { sort = {}, filter = {} } = props;
        const url =
            PUBLIC_RESOURCES.indexOf(resource) > -1
                ? resource
                : `${prefixAPI}${resource}`;
        const response = await apis.get(url, {
            params: {
                page_size: perPage,
                page_index: page,
                [sort['field']]: sort['order'],
                ...filter,
            },
        });
        const isStatistics = resource.includes('statistics');
        if (isStatistics) return response;

        const { data, pagination } = response;
        return { data, total: pagination.total || data.length };
    },
    getOne: async (resource, params) => {
        const { id } = params;
        const PUBLIC_RESOURCES = [
            'package',
            'plan',
            'brandpals/order',
            'discount',
        ];
        if (PUBLIC_RESOURCES.indexOf(resource) > -1) {
            const url =
                resource[resource.length - 1] !== '/'
                    ? `${resource}/${id}`
                    : `${resource}${id}`;
            const res = await apis.get(url);
            return { data: res };
        } else {
            const url =
                resource[resource.length - 1] !== '/'
                    ? `${prefixAPI}${resource}/${id}`
                    : `${prefixAPI}${resource}${id}`;
            const res = await apis.get(url);
            return { data: res };
        }
    },
    getMany: (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    renewOrder: async (resource, params) => {
        const { id, data } = params;
        const res = await apis.post(`${prefixAPI}${resource}${id}`, {
            data,
        });
        return { data: { id, ...res } };
    },
    create: async (resource, params) => {
        const { data } = params;
        const res = await apis.post(`${prefixAPI}${resource}`, data);
        return { data: res };
    },
    update: async (resource, params) => {
        if (resource === 'summary') resource = 'order/summary';
        const { id, data } = params;
        const url = getUrl(resource, id);
        const res = await apis.patch(url, {
            ...data,
        });

        return { data: res };
    },
    updateMany: (resource, params) => Promise,
    delete: async (resource, params) => {
        const { id, data } = params;
        const url = getUrl(resource, id);
        const res = await apis.delete(url, {
            data,
        });
        return { data: res };
    },
    deleteMany: (resource, params) => Promise,
};
