import { DOMAINS } from 'constants/APP_CONFIG';
import BRAND_TYPE from 'constants/BRAND_TYPE';
import {
    Create,
    TextInput,
    SimpleForm,
    SelectInput,
    required,
    DateTimeInput,
    NumberInput,
    BooleanInput,
} from 'react-admin';

const CouponCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="code" validate={[required()]} />
            <NumberInput source="value" validate={[required()]} />
            <SelectInput
                source="type"
                defaultValue="percent"
                choices={[
                    { id: 'percent', name: 'Percent (%)' },
                    { id: 'fixed_amount', name: 'Fixed Amount ($)' },
                ]}
            />
            <SelectInput source="domain" defaultValue="" choices={DOMAINS} />
            <NumberInput
                source="limit_times"
                min={0}
                label="The total number of times"
                helperText="Limit the total number of times this coupon can be redeemed"
            />
            <DateTimeInput
                source="limit_start_date"
                label="The start date"
                helperText="Limit the date range when customers can redeem this coupon (UTC)"
            />
            <DateTimeInput
                source="limit_end_date"
                label="The end date"
                helperText="Limit the date range when customers can redeem this coupon (UTC)"
            />
            <SelectInput source="brand_type" choices={BRAND_TYPE} />
            <BooleanInput source="enable" label="Enable" />
        </SimpleForm>
    </Create>
);

export default CouponCreate;
