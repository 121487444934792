import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { formatNumber, getDataStatistics } from 'helpers/statistics';
import ListInfoItem from '../../list-info-item';
// import './styles.css';

const StatisticsList = ({ data, title, total, price, charge }) => {
    const { dataStatus } = getDataStatistics(data || []);

    return (
        <Box
            className="chart-status"
            height="auto"
            width="100%"
            display="flex"
            flexDirection="column"
            borderRadius="5px"
            boxShadow="0 0 5px #bebebe"
            p="10px"
        >
            <h3>{title}</h3>
            <Box width="100%" className="total-statistics">
                <Grid container spacing={0} className="title-total-statistics">
                    <Grid item xs={6} md={4}>
                        <Box>
                            <h3>Total:</h3>
                            <h4>{formatNumber(total)}</h4>
                        </Box>
                    </Grid>
                    {!!price && (
                        <Grid item xs={6} md={4}>
                            <Box>
                                <h3>Price:</h3>
                                <h4>{formatNumber(price)}</h4>
                            </Box>
                        </Grid>
                    )}
                    {!!charge && (
                        <Grid item xs={6} md={4}>
                            <Box>
                                <h3>Charge:</h3>
                                <h4>{formatNumber(charge)}</h4>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <ListInfoItem data={dataStatus} lg={4} xs={6} />
            </Box>
        </Box>
    );
};
export default StatisticsList;
