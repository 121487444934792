import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    useNotify,
} from 'react-admin';

import APP_CONFIG from 'constants/APP_CONFIG';

const OrderEdit = (props) => {
    const notify = useNotify();
    const onFailure = (error) => {
        notify(error, 'error');
    };
    return (
        <Edit
            {...props}
            title="Edit Order"
            onFailure={onFailure}
            undoable={false}
        >
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput disabled source="brand_type" />
                <TextInput disabled source="action_type" />
                <TextInput disabled source="order_number" />
                <p style={{ fontWeight: 'bold' }}>Information: </p>
                <SelectInput
                    source="status"
                    choices={APP_CONFIG.ORDERS.FILTERS.status}
                />
                <TextInput source="quantity" />
                <TextInput source="price" />
                <TextInput source="desc" />
                <TextInput source="url" />
                <p style={{ fontWeight: 'bold' }}>SMM Information: </p>
                <TextInput source="smm_order_id" label="Smm ID" />
                <TextInput source="smm_charge" />
                <TextInput source="smm_service_name" />
                <SelectInput
                    source="smm_service_status"
                    choices={APP_CONFIG.ORDERS.FILTERS.smm_status}
                />
                <TextInput source="start_count" />
                <TextInput source="delivered" />
            </SimpleForm>
        </Edit>
    );
};

export default OrderEdit;
