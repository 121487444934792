import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SelectInput,
    EditButton,
    DeleteWithConfirmButton,
    TextInput,
    DateField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import { DOMAINS } from 'constants/APP_CONFIG';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const PostFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="domain"
            label="Domain"
            choices={DOMAINS}
            alwaysOn
        />
        <TextInput source="account" label="Account" alwaysOn />
    </Filter>
);

const StripeAccountList = (props) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="Discount offer"
            filters={<PostFilter />}
            sort={{ field: 'order_by' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="id" label="ID" />
                <TextField source="account" label="Account" />
                <TextField source="domain" label="Domain" />
                <TextField source="usage_count" label="Usage Count" />
                <DateField
                    source="last_time_used"
                    label="Last Time Used"
                    showTime="true"
                />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default StripeAccountList;
