import BRAND_TYPE from 'constants/BRAND_TYPE';
import {
    Create,
    TextInput,
    SimpleForm,
    SelectInput,
    required,
    BooleanInput,
} from 'react-admin';

const BrandCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" validate={[required()]} />
            <SelectInput
                source="brand_type"
                choices={BRAND_TYPE}
                validate={[required()]}
            />
            <BooleanInput
                source="enabled"
                label="Enabled"
                defaultValue={true}
            />
        </SimpleForm>
    </Create>
);

export default BrandCreate;
