import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { formatNumber, getDataStatisticsItems } from 'helpers/statistics';
// import './styles.css';
import ListStatisticsActionType from './statistics-action-type';
import StatusOrderChart from '../../chart';
import BoxItemColor from '../../box-color';

const StatisticsItem = ({ data, title, total }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        actionType,
        data: dataList,
        totalStatus = {},
    } = getDataStatisticsItems(data || []);
    return (
        <Box
            className="chart-status"
            height="auto"
            width="100%"
            display="flex"
            flexDirection="column"
            borderRadius="5px"
            boxShadow="0 0 5px #bebebe"
            p="10px"
        >
            <h3 style={{ textTransform: 'capitalize' }}>{title}</h3>
            <Box display="flex" width="100%" gridGap="20px">
                <Box width="200px" height="200px">
                    <StatusOrderChart data={dataList} />
                </Box>
                <Box
                    className="chart-status-right"
                    width="calc(100% - 200px - 20px)"
                >
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <h3>Total: {formatNumber(total)}</h3>
                            </Box>
                        </Grid>
                        {!!Object.keys(totalStatus).length &&
                            Object.keys(totalStatus).map((el, idx) => (
                                <Grid
                                    key={`data-status-${idx}`}
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Box>
                                        <h3
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {el}: {formatNumber(totalStatus[el])}
                                        </h3>
                                    </Box>
                                </Grid>
                            ))}
                    </Grid>
                    <BoxItemColor
                        data={actionType}
                        gap={isMobile ? 4 : 8}
                        sizeBox={isMobile ? '15px' : '20px'}
                    />
                </Box>
            </Box>
            <ListStatisticsActionType data={data} />
        </Box>
    );
};
export default StatisticsItem;
