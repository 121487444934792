import React from 'react';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const SelectForm = ({ title, option, handleChangeStatus, status }) => (
    <FormControl fullWidth>
        <InputLabel id="select-label">{title}</InputLabel>
        <Select
            labelId="select-label"
            value={status}
            onChange={handleChangeStatus}
        >
            <MenuItem value="">All</MenuItem>
            {option.map((item, index) => {
                return (
                    <MenuItem value={item?.id} key={`${index}-${item.id}`}>
                        {item.name}
                    </MenuItem>
                );
            })}
        </Select>
    </FormControl>
);

export default SelectForm;
