import { Box, Grid } from '@material-ui/core';
import StatisticsList from './statistics-list';

const SectionTotal = ({ generals, generalTotal, panelTotal, panels }) => {
    if(!panelTotal && !generalTotal) return <></>;
    return (
        <Box className="shadow-statistics" display="flex" mt="20px" p="20px">
            <Grid container spacing={4}>
                <Grid container item xs={12} lg={6}>
                    <StatisticsList
                        title="Total General"
                        total={generalTotal?.total}
                        price={generalTotal?.price}
                        charge={generalTotal?.charge}
                        data={generals}
                    />
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <StatisticsList
                        title="Total Panel"
                        total={panelTotal?.total}
                        data={panels}
                        price={panelTotal?.price}
                        charge={panelTotal?.charge}
                    />
                </Grid>
            </Grid>
        </Box>
    )
};

export default SectionTotal;
