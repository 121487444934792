const APP_CONFIG = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    ORDERS: {
        FILTERS: {
            status: [
                {
                    name: 'Pending',
                    id: 'pending',
                },
                {
                    name: 'Confirmed',
                    id: 'confirmed',
                },
                {
                    name: 'In Progress',
                    id: 'in_progress',
                },
                {
                    name: 'Completed',
                    id: 'completed',
                },
                {
                    name: 'Canceled',
                    id: 'canceled',
                },
                {
                    name: 'Draft',
                    id: 'draft',
                },
            ],
            action_type: [
                {
                    name: 'View',
                    id: 'view',
                },
                {
                    name: 'Like',
                    id: 'like',
                },
                {
                    name: 'Youtube - Subscriber',
                    id: 'subscriber',
                },
                {
                    name: 'Follower',
                    id: 'follower',
                },
                {
                    name: 'User Follower',
                    id: 'user_follower',
                },
                {
                    name: 'Comment',
                    id: 'comment',
                },
                {
                    name: 'Share',
                    id: 'share',
                },
                {
                    name: 'Play',
                    id: 'play',
                },
                {
                    name: 'Listener',
                    id: 'listener',
                },
                {
                    name: 'Save',
                    id: 'save',
                },
                {
                    name: 'Live stream in 30 minutes',
                    id: 'live_stream_30_minutes',
                },
                {
                    name: 'Live stream in 1 hour',
                    id: 'live_stream_1_hour',
                },
                {
                    name: 'Live stream in 2 hours',
                    id: 'live_stream_2_hours',
                },
                {
                    name: 'Live stream in 3 hours',
                    id: 'live_stream_3_hours',
                },
                {
                    name: 'Live stream in 6 hours',
                    id: 'live_stream_6_hours',
                },
                {
                    name: 'Twitter Retweets',
                    id: 'retweet',
                },
                {
                    name: 'Youtube Watch Hour',
                    id: 'watch_hour',
                },
            ],
            brand_type: [
                {
                    name: 'Youtube',
                    id: 'youtube',
                },
                {
                    name: 'Tiktok',
                    id: 'tiktok',
                },
                {
                    name: 'Facebook',
                    id: 'facebook',
                },
                {
                    name: 'Instagram',
                    id: 'instagram',
                },
                {
                    name: 'Twitter',
                    id: 'twitter',
                },
                {
                    name: 'Spotify',
                    id: 'spotify',
                },
                {
                    name: 'Soundcloud',
                    id: 'soundcloud',
                },
                {
                    name: 'Twitch',
                    id: 'twitch',
                },
            ],
            smm_status: [
                {
                    name: 'Draft',
                    id: 'Draft',
                },
                {
                    name: 'Pending',
                    id: 'Pending',
                },
                {
                    name: 'In Progress',
                    id: 'In progress',
                },
                {
                    name: 'Completed',
                    id: 'Completed',
                },
                {
                    name: 'Partial',
                    id: 'Partial',
                },
                {
                    name: 'Canceled',
                    id: 'Canceled',
                },
                {
                    name: 'Error',
                    id: 'Error',
                },
                {
                    name: 'Waiting',
                    id: 'Waiting',
                },
            ],
            payment_type: [
                { name: 'PAYPAL', id: 'paypal' },
                { name: 'IPAYTOTAL', id: 'ipaytotal' },
                { name: 'COINBASE', id: 'coinbase' },
                { name: 'PAYOP', id: 'payop' },
                { name: 'STRIPE', id: 'stripe' },
                { name: 'GBPRIME_PAY', id: 'gbprimepay' },
                { name: 'CARDINITY', id: 'cardinity' },
                { name: 'RAPYD', id: 'rapyd' },
                { name: 'WALLET', id: 'wallet' },
            ],
        },
    },
    SUBSCRIPTION: {
        status: [
            {
                name: 'Draft',
                id: 'Draft',
            },
            {
                name: 'Active',
                id: 'Active',
            },
            {
                name: 'Paused',
                id: 'Paused',
            },
            {
                name: 'Completed',
                id: 'Completed',
            },
            {
                name: 'Expired',
                id: 'Expired',
            },
            {
                name: 'Canceled',
                id: 'Canceled',
            },
        ],
        brand_type: [
            {
                name: 'Tiktok',
                id: 'tiktok',
            },
            {
                name: 'Instagram',
                id: 'instagram',
            },
        ],
        action_type: [
            {
                name: 'View',
                id: 'view',
            },
            {
                name: 'Like',
                id: 'like',
            },
            {
                name: 'Comment',
                id: 'comment',
            },
            {
                name: 'Share',
                id: 'share',
            },
        ],
    },
    BRANDPALS: {
        plan: [
            { name: 'Lite', id: 'lite' },
            { name: 'Plus', id: 'plus' },
            { name: 'Elite', id: 'elite' },
        ],
        status: [
            {
                name: 'Draft',
                id: 'draft',
            },
            {
                name: 'Pending',
                id: 'pending',
            },
            {
                name: 'In Progress',
                id: 'in_progress',
            },
            {
                name: 'Completed',
                id: 'completed',
            },
            {
                name: 'Canceled',
                id: 'Canceled',
            },
            {
                name: 'Confirmed',
                id: 'confirmed',
            },
        ],
        payment_status: [
            {
                name: 'Unknow',
                id: 'unknow',
            },
            {
                name: 'Pending',
                id: 'pending',
            },
            {
                name: 'Paid',
                id: 'paid',
            },
            {
                name: 'New',
                id: 'new',
            },
            {
                name: 'Refund',
                id: 'refund',
            },
            {
                name: 'Failed',
                id: 'failed',
            },
            {
                name: 'Expired',
                id: 'expired',
            },
            {
                name: 'Unpaid',
                id: 'unpaid',
            },
            {
                name: 'No payment required',
                id: 'no_payment_required',
            },
            {
                name: 'Approved',
                id: 'approved',
            },
            {
                name: 'Declined',
                id: 'declined',
            },
        ],
    },
};

export default APP_CONFIG;

export const SMM_SERVICE_STATUS = [
    {
        id: 'active',
        name: 'Active',
    },
    {
        id: 'inactive',
        name: 'Inactive',
    },
];
export const PACKAGE_NAME = [
    { id: 'Starter', name: 'Starter' },
    { id: 'Creator', name: 'Creator' },
    { id: 'Influencer', name: 'Influencer' },
    { id: 'BASIC PLAN', name: 'BASIC PLAN' },
    { id: 'PRO PLAN', name: 'PRO PLAN' },
    { id: 'ELITE PLAN', name: 'ELITE PLAN' },
];
export const PRIORITY = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
];
export const STATUS_ORDER = [
    {
        name: 'Pending',
        id: 'pending',
    },
    {
        name: 'Confirmed',
        id: 'confirmed',
    },
    {
        name: 'In Progress',
        id: 'in_progress',
    },
    {
        name: 'Completed',
        id: 'completed',
    },
    {
        name: 'Canceled',
        id: 'canceled',
    },
];
export const DATE_RANGER = [
    {
        name: 'TODAY',
        id: 'TODAY',
    },
    {
        name: 'YESTERDAY',
        id: 'YESTERDAY',
    },
    {
        name: 'LAST_WEEK',
        id: 'LAST_WEEK',
    },
    {
        name: 'THIS_WEEK',
        id: 'THIS_WEEK',
    },
    {
        name: 'LAST_7_DAYS',
        id: 'LAST_7_DAYS',
    },
    {
        name: 'LAST_14_DAYS',
        id: 'LAST_14_DAYS',
    },
    {
        name: 'LAST_30_DAYS',
        id: 'LAST_30_DAYS',
    },
    {
        name: 'THIS_MONTH',
        id: 'THIS_MONTH',
    },
];

export const COLORS = [
    '#3A9C99',
    '#61C39F',
    '#FA5C4F',
    '#8C57D0',
    '#E2B40F',
    '#A61F8E',
    '#27B0C8',
    '#B7DC77',
    '#F48E42',
    '#D4E8FF',
];
export const PACKAGE_BRAND_TYPE = [
    { id: 'tiktok', name: 'tiktok' },
    { id: 'instagram', name: 'instagram' },
];

export const DOMAINS = [
    {
        id: 'tubekick',
        name: 'Tubekick',
    },
    { id: 'viewpals', name: 'Viewpals' },
    {
        id: 'streamingpals',
        name: 'Streamingpals',
    },
    {
        id: 'streamkick',
        name: 'Streamkick',
    },
    {
        id: 'tiktribe',
        name: 'Tiktribe',
    },
    {
        id: 'tikfame',
        name: 'Tikfame',
    },
    {
        id: 'streamsocial',
        name: 'Streamsocial',
    },
    {
        id: 'brandpals',
        name: 'Brandpals',
    },
    {
        id: 'tikwave',
        name: 'Tikwave',
    },
    {
        id: 'tikrocket',
        name: 'Tikrocket',
    },
];
