import { Box, Grid } from '@material-ui/core';
import StatisticsItem from './statistics-item';

const SectionItem = ({ data, title }) => {
    if (!data?.length) return <></>;

    return (
        <Box className="shadow-statistics section-item" mt="20px" p="20px">
            <h3>{title}</h3>
            <Grid container spacing={4}>
                {data.map((item, idx) => (
                    <Grid
                        key={`section-item-${idx}-${item.brand_type}`}
                        container
                        item
                        xs={12}
                        lg={6}
                    >
                        <StatisticsItem
                            title={item.brand_type}
                            total={item.total}
                            data={item.data || []}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SectionItem;
