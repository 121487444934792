import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ArrayField,
    NumberField,
    BooleanField,
    EditButton,
    DeleteWithConfirmButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const MinMaxField = (props) => {
    const { record, source } = props || {};

    if (!record) return null;

    return (
        <div>
            {record[source] && <p>Min : {record[source].minimum}</p>}
            {record[source] && <p>Max : {record[source].maximum}</p>}
        </div>
    );
};

const PackageList = (props) => {
    const classes = useStyles();
    return (
        <List {...props} title="Package list">
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="name" />
                <TextField source="brand_type" />
                <TextField source="price" />
                <TextField source="description" />
                <MinMaxField source="view" label={'View'} />
                <MinMaxField source="like" label={'Like'} />
                <MinMaxField source="share" label={'Share'} />
                <MinMaxField source="comment" label={'Comment'} />
                <MinMaxField source="follower" label={'Follower'} />
                <ArrayField source="discounts">
                    <Datagrid>
                        <NumberField source="post_quantity" />
                        <NumberField source="percent" />
                    </Datagrid>
                </ArrayField>
                <TextField source="priority" />
                <BooleanField source="is_flash_sale" />
                <BooleanField source="is_popular" />
                <NumberField source="posts" />
                <TextField source="domain" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default PackageList;
