import {
    DOMAINS,
    PACKAGE_BRAND_TYPE,
    PACKAGE_NAME,
    PRIORITY,
} from 'constants/APP_CONFIG';
import { useState } from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    required,
    NumberInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    minValue,
} from 'react-admin';
import { useForm } from 'react-final-form';

const PackageCreate = (props) => {
    const [brand, setBrand] = useState('Tiktok');

    const BrandInput = ({ variant, ...props }) => {
        const form = useForm();

        const handleChange = (e) => {
            if (e.target.value === 'instagram') {
                form.change('share.minimum', undefined);
                form.change('share.maximum', undefined);
            }
            setBrand(e.target.value);
        };
        return (
            <SelectInput
                source="brand_type"
                choices={PACKAGE_BRAND_TYPE}
                label="Brand type"
                validate={required()}
                onChange={handleChange}
            />
        );
    };
    return (
        <Create {...props}>
            <SimpleForm>
                <SelectInput
                    source="name"
                    choices={PACKAGE_NAME}
                    label="Name"
                    validate={required()}
                />
                <BrandInput label="Brand type" source="brand_type" />
                <TextInput source="description" validate={[required()]} />
                <NumberInput
                    source="price"
                    validate={[required(), minValue(1)]}
                />
                <NumberInput source="posts" />
                <NumberInput
                    source="view.minimum"
                    label="Min view"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="view.maximum"
                    label="Max view"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="like.minimum"
                    label="Min like"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="like.maximum"
                    label="Max like"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="share.minimum"
                    label="Min share"
                    parse={(v) => parseInt(v)}
                    disabled={brand === 'instagram'}
                />
                <NumberInput
                    source="share.maximum"
                    label="Max share"
                    parse={(v) => parseInt(v)}
                    disabled={brand === 'instagram'}
                />
                <NumberInput
                    source="comment.minimum"
                    label="Min comment"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="comment.maximum"
                    label="Max comment"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="follower.minimum"
                    label="Min follower"
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="follower.maximum"
                    label="Max follower"
                    parse={(v) => parseInt(v)}
                />
                <ArrayInput source="discounts">
                    <SimpleFormIterator>
                        <NumberInput
                            source="post_quantity"
                            label="Post quantity"
                            validate={[required(), minValue(1)]}
                            parse={(v) => parseInt(v)}
                        />
                        <NumberInput
                            source="percent"
                            label="Percent"
                            validate={[required(), minValue(1)]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput
                    source="priority"
                    choices={PRIORITY}
                    label="Priority"
                    validate={required()}
                />
                <BooleanInput source="is_flash_sale" label="Is flash sale" />
                <BooleanInput label="Is most popular" source="is_popular" />
                <SelectInput
                    source="domain"
                    choices={DOMAINS}
                    label="Domain"
                    validate={required()}
                />
            </SimpleForm>
        </Create>
    );
};

export default PackageCreate;
