import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    DateField,
    BooleanField,
    ArrayField,
    EditButton,
    Filter,
    TextInput,
    SelectInput,
    useNotify,
    useDataProvider,
} from 'react-admin';
import { makeStyles, Button } from '@material-ui/core';
import { startCase } from 'lodash';
import APP_CONFIG from 'constants/APP_CONFIG';
import { useCallback } from 'react';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const ObjectField = (props) => {
    const { record, source, field } = props || {};
    if (!record) return null;
    if (!record[source]) return null;
    return (
        <div>
            {field.map((item) => (
                <p>
                    {Object.keys(item)[0]} :
                    {record[source][item[Object.keys(item)[0]]]}
                </p>
            ))}
        </div>
    );
};
const ContactFieldArray = [
    { 'Brand Name': 'brand_name' },
    { 'Full Name': 'fullname' },
    { Email: 'email' },
    { Country: 'country' },
    { Address: 'address' },
    { Phone: 'phone' },
];
const ContentField = [
    {
        'Article Topic': 'article_topic',
    },
    { Keyword: 'keyword' },
    { Note: 'note' },
];

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" placeholder="Email" type="email" alwaysOn />
        <SelectInput
            source="plan"
            label="Plan"
            choices={APP_CONFIG.BRANDPALS.plan}
            alwaysOn
        />
        <SelectInput
            source="status"
            label="Status"
            choices={APP_CONFIG.BRANDPALS.status}
            alwaysOn
        />
        <SelectInput
            source="payment_status"
            label="Payment Status"
            choices={APP_CONFIG.BRANDPALS.payment_status}
            alwaysOn
        />
        <TextInput
            source="order_id"
            placeholder="Order ID"
            type="text"
            alwaysOn
        />
    </Filter>
);

const ButtonCustom = (props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            data-id={props.record.id}
            onClick={props.onClick}
        >
            Initital
        </Button>
    );
};

const BrandpalsList = (props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const onClickRenew = useCallback(
        async (evt) => {
            evt.preventDefault();
            const { id } = evt.currentTarget.dataset;
            try {
                const { data } = await dataProvider.renewOrder(
                    `brandpals/order/panel/`,
                    {
                        data: {},
                        id,
                    },
                );
                if (data && data.success) {
                    notify('Initital successful');
                } else {
                    notify('Initital failed', 'error');
                }
            } catch (e) {
                notify('Initital failed', 'error');
            }
        },
        [dataProvider, notify],
    );
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="List of Brandpals"
            filters={<PostFilter />}
            sort={{ field: 'order_by', order: 'desc' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="site_url" label="Site URL" />
                <TextField source="fullname" label="Full name" />
                <TextField source="email" label="Email" />
                <NumberField source="price" label="Price" />
                <TextField source="domain" label="Domain" />
                <FunctionField
                    label="Status"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(record.status.replace(/_/g, ' '))}`
                    }
                />
                <FunctionField
                    label="Payment Status"
                    textAlign="right"
                    render={(record) =>
                        `${startCase(record.payment_status.replace(/_/g, ' '))}`
                    }
                />
                <TextField source="payment_type" label="Payment Type" />
                <TextField source="plan" label="Plan" />
                <BooleanField source="is_alternative" label="Is Alternative" />
                <ObjectField
                    source="contact"
                    label="Contact"
                    field={ContactFieldArray}
                />
                <ObjectField
                    source="content"
                    label="Content"
                    field={ContentField}
                />
                <TextField source="payment_id" label="Payment ID" />
                <TextField source="order_number" label="Order Number" />
                <NumberField source="row_id" label="Row ID" />
                <NumberField source="panel_price" label="Panel Price" />
                <NumberField source="panel_id" label="Panel ID" />
                <TextField source="id" label="ID" />
                <ArrayField source="files">
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="mime_type" />
                        <TextField source="url" />
                    </Datagrid>
                </ArrayField>
                <DateField source="created_at" label="Created At" />
                <DateField source="updated_at" label="Updated At" />
                <EditButton />
                <ButtonCustom onClick={onClickRenew} />
            </Datagrid>
        </List>
    );
};

export default BrandpalsList;
