import {
    TextInput,
    NumberInput,
    Edit,
    SimpleFormIterator,
    ArrayInput,
    required,
    SimpleForm,
    SelectInput,
    BooleanInput,
    minValue,
} from 'react-admin';

import {
    PACKAGE_NAME,
    PRIORITY,
    PACKAGE_BRAND_TYPE,
} from 'constants/APP_CONFIG';
import { useEffect, useState } from 'react';

const PackageEdit = (props) => {
    const [brand, setBrand] = useState('tiktok');
    const GetBrand = ({ record }) => {
        useEffect(() => {
            setBrand(record.brand_type);
        }, [record.brand_type]);
        return <div></div>;
    };
    return (
        <Edit {...props}>
            <SimpleForm>
                <SelectInput
                    source="name"
                    choices={PACKAGE_NAME}
                    label="Name"
                    validate={required()}
                    disabled
                />
                <SelectInput
                    source="brand_type"
                    choices={PACKAGE_BRAND_TYPE}
                    label="brand_type"
                    validate={required()}
                    disabled
                />
                <GetBrand source="brand_type" />
                <NumberInput
                    source="price"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                    label="Price"
                />
                <TextInput
                    source="description"
                    validate={required()}
                    label="Description"
                />
                <NumberInput
                    source="view.minimum"
                    label="Min view"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="view.maximum"
                    label="Max view"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="share.minimum"
                    label="Min share"
                    parse={(v) => parseInt(v)}
                    disabled={brand === 'instagram'}
                />
                <NumberInput
                    source="share.maximum"
                    label="Max share"
                    parse={(v) => parseInt(v)}
                    disabled={brand === 'instagram'}
                />
                <NumberInput
                    source="comment.minimum"
                    label="Min comment"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="comment.maximum"
                    label="Max comment"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="like.minimum"
                    label="Min like"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="like.maximum"
                    label="Max like"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="follower.minimum"
                    label="Min follower"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <NumberInput
                    source="follower.maximum"
                    label="Max follower"
                    validate={[required(), minValue(1)]}
                    parse={(v) => parseInt(v)}
                />
                <ArrayInput source="discounts">
                    <SimpleFormIterator>
                        <NumberInput
                            source="post_quantity"
                            label="Post quantity"
                            validate={[required(), minValue(1)]}
                            parse={(v) => parseInt(v)}
                        />
                        <NumberInput
                            source="percent"
                            label="Percent"
                            validate={[required(), minValue(1)]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput
                    source="priority"
                    choices={PRIORITY}
                    label="Priority"
                    validate={required()}
                />
                <BooleanInput label="Is most popular" source="is_popular" />
            </SimpleForm>
        </Edit>
    );
};

export default PackageEdit;
