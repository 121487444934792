import React, { useState, useMemo, useEffect } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import SelectForm from 'components/Dashboard/components/filter-order';
import APP_CONFIG, { DATE_RANGER } from 'constants/APP_CONFIG';

import SelectDateForm from './components/fillter-by-date';
import SectionLabel from './components/section-label';
import SectionTotal from './components/section-total';
import { dataProvider } from 'helpers/instanceApi';
import SectionItem from './components/section-item';
import Skeleton from './components/skeleton';
import { useHistory } from 'react-router-dom';

import './styles.css';

const OrderList = () => {
    const [generals, setGenerals] = useState(undefined);
    const [panels, setPanels] = useState(undefined);
    const [panelTotal, setPanelTotal] = useState(undefined);
    const [generalTotal, setGeneralTotal] = useState(undefined);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [status, setstatus] = useState();
    const [dateRange, setDateRange] = useState();

    const history = useHistory();

    const handleDateChangeStartDate = (event) => {
        setStartDate(event.target.value || undefined);
    };

    const handleDateChangeEndDate = (event) => {
        setEndDate(event.target.value || undefined);
    };

    const handleChangeStatus = (event) => {
        setstatus(event.target.value || undefined);
    };

    const handleChangeDateRanger = (event) => {
        setDateRange(event.target.value || undefined);
    };

    const filter = useMemo(() => {
        return {
            start_date: startDate,
            end_date: endDate,
            status: status,
            date_range: dateRange,
        };
    }, [startDate, status, endDate, dateRange]);

    useEffect(() => {
        (async () => {
            try {
                setError('');
                setLoading(true);
                const { general, panel } = await dataProvider.getList(
                    'order/statistics',
                    { filter },
                );

                if (!general && !panel) throw new Error('Data is empty!');

                setPanels(panel.data);
                setGenerals(general.data);
                setPanelTotal({
                    price: panel.price || 0,
                    total: panel.total || 0,
                    charge: panel.charge || 0,
                });
                setGeneralTotal({
                    price: general.price || 0,
                    total: general.total || 0,
                    charge: general.charge || 0,
                });
            } catch (error) {
                if (typeof error === 'string') return setError(error);
                const { message, status } = error;
                if (status === 401 || status === 403)
                    return history.push('/login');
                return setError(message);
            } finally {
                setLoading(false);
            }
        })();
    }, [filter, history]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box className="box-statistics">
            <Box className="shadow-statistics" p="20px">
                <Grid container spacing={isMobile ? 2 : 10}>
                    <Grid item xs={12} md={3}>
                        <SelectForm
                            option={DATE_RANGER}
                            title="Date Range"
                            handleChangeStatus={handleChangeDateRanger}
                            status={dateRange || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectForm
                            option={APP_CONFIG.ORDERS.FILTERS.status}
                            title="Status"
                            handleChangeStatus={handleChangeStatus}
                            status={status || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectDateForm
                            option={APP_CONFIG.ORDERS.FILTERS.action_type}
                            handleDateChange={handleDateChangeStartDate}
                            label="Start date"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectDateForm
                            option={APP_CONFIG.ORDERS.FILTERS.action_type}
                            handleDateChange={handleDateChangeEndDate}
                            label="End date"
                        />
                    </Grid>
                </Grid>
            </Box>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    {!error ? (
                        <>
                            <SectionLabel
                                generalTotal={generalTotal}
                                panelTotal={panelTotal}
                            />
                            <SectionTotal
                                generalTotal={generalTotal}
                                generals={generals}
                                panelTotal={panelTotal}
                                panels={panels}
                            />
                            <SectionItem data={generals} title="General" />
                            <SectionItem data={panels} title="Panel" />
                        </>
                    ) : (
                        <>{error}</>
                    )}
                </>
            )}
        </Box>
    );
};

export default OrderList;
