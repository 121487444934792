import { List, Datagrid, TextField, EditButton } from "react-admin";

const SMMsList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="desc" />
      <TextField source="status" />
      <TextField source="api_url" />
      <TextField source="api_key" />
      <EditButton />
    </Datagrid>
  </List>
);
export default SMMsList;
