import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    Filter,
    SelectInput,
    EditButton,
    DeleteWithConfirmButton,
    ArrayField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import APP_CONFIG, { DOMAINS } from 'constants/APP_CONFIG';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const PostFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="domain"
            label="Domain"
            choices={DOMAINS}
            alwaysOn
        />
        <SelectInput
            source="brand_type"
            label="Brand"
            choices={APP_CONFIG.ORDERS.FILTERS.brand_type}
            alwaysOn
        />
    </Filter>
);

const OrderList = (props) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="Discount offer"
            filters={<PostFilter />}
            sort={{ field: 'order_by' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="id" label="ID" />
                <TextField source="brand_type" label="Brand Type" />
                <TextField source="domain" label="Domain" />
                <ArrayField source="discount" label="Discounts">
                    <Datagrid>
                        <NumberField source="amount" />
                        <NumberField source="percent" />
                    </Datagrid>
                </ArrayField>
                <DateField showTime source="created_at" label="Created at" />
                <DateField showTime source="updated_at" label="Updated at" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default OrderList;
