export const PLAN_TYPE = [
    {
      name: 'Lite',
      id: 'lite',
    },
    {
      name: 'Plus',
      id: 'plus',
    },
    {
      name: 'Elite',
      id: 'elite',
    }
  ];
  
  export const STATUS = [
    {
        id: 'active',
        name: 'Active',
    },
    {
        id: 'inactive',
        name: 'Inactive',
    },
];
