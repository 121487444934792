import * as React from 'react';

import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from 'auth/authProvider';

import Orders from 'components/Orders';
import Channel from 'components/Channel';
import SMMs from 'components/SMMs';

import { dataProvider } from 'helpers/instanceApi';
import PrivatePanelEdit from 'components/PrivateService/Edit';
import Coupons from 'components/Coupon';
import Discount from 'components/Discount';
import Brand from 'components/Brand';
import Package from 'components/Package';
import Plans from 'components/Plans';
import Subscription from 'components/Subscription';
import BrandPals from 'components/BrandPals';
import Dashboard from 'components/Dashboard';
import StripeAccount from 'components/StripeAccount';

import './App.css';
import OrderSummary from 'components/OrderSummary';

const i18Messages = {
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
    (locale) => i18Messages[locale],
    'en',
    { allowMissing: true },
);

const App = () => (
    <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        customRoutes={[
            <Route
                exact
                path="/panelService/:panelId/edit/:id"
                component={(props) => <PrivatePanelEdit {...props} />}
            />,
        ]}
    >
        <Resource
            name="dashboard/"
            options={{ label: 'Dashboard' }}
            {...Dashboard}
        />
        <Resource name="orders/" options={{ label: 'Orders' }} {...Orders} />
        <Resource
            options={{ label: 'Private Service', title: 'Private Service' }}
            name="smms/"
            {...SMMs}
        />
        <Resource name="channel" options={{ label: 'Channel' }} {...Channel} />
        <Resource name="coupon" options={{ label: 'Coupon' }} {...Coupons} />
        <Resource name="brand" options={{ label: 'Brand' }} {...Brand} />
        <Resource name="package" options={{ label: 'Package' }} {...Package} />
        <Resource name="plan" options={{ label: 'Plans' }} {...Plans} />
        <Resource
            name="subscription"
            options={{ label: 'Subscription' }}
            {...Subscription}
        />
        <Resource
            name="brandpals/order"
            options={{ label: 'Brandpals' }}
            {...BrandPals}
        />
        <Resource
            name="summary"
            options={{ label: 'Order summary' }}
            {...OrderSummary}
        />
        <Resource
            name="discount"
            options={{ label: 'Discount' }}
            {...Discount}
        />
        <Resource
            name="stripe_account"
            options={{ label: 'Stripe Account' }}
            {...StripeAccount}
        />
    </Admin>
);

export default App;
