import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'block',
    marginBottom: theme.spacing(3),
    animation: '$skeletonAnimation 1.5s linear infinite',
  },
  height1: {
    height: '150px',
  },
  height2: {
    height: '100px',
  },
  height3: {
    height: '50px',
  },
  '@keyframes skeletonAnimation': {
    '0%': {
      opacity: 0.4,
    },
    '50%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0.4,
    },
  },
}));

const Skeleton = () => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column' mt={3} >
      <div className={`${classes.skeleton} ${classes.height1}`} />
      <div className={`${classes.skeleton} ${classes.height2}`} />
      <div className={`${classes.skeleton} ${classes.height3}`} />
    </Box>
  );
};

export default Skeleton;