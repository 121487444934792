import { COLORS } from "constants/APP_CONFIG";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const StatusOrderChart = ({
    data,
    width = 200,
    height = 200,
    cx = 95,
    cy = 95,
    outer = 90,
    inner = 70,
}) => (
    <ResponsiveContainer width="100%" height="100%">
        <PieChart width={width} height={height} className="?">
            <Pie
                data={data}
                cx={cx}
                cy={cy}
                labelLine={false}
                outerRadius={outer}
                innerRadius={inner}
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                    />
                ))}
            </Pie>
            <Tooltip />
        </PieChart>
    </ResponsiveContainer>
);

export default StatusOrderChart;