import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    DateInput,
    Filter,
    SelectInput,
    TextInput,
    BooleanField,
    useRedirect,
} from 'react-admin';

import { Button, makeStyles } from '@material-ui/core';

import APP_CONFIG from 'constants/APP_CONFIG';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});
const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput
            source="order_number"
            placeholder="Order number"
            type="text"
            alwaysOn
        />
        <SelectInput
            source="brand_type"
            placeholder="Brand type"
            choices={APP_CONFIG.ORDERS.FILTERS.brand_type}
            alwaysOn
        />
        <SelectInput
            source="payment_type"
            placeholder="Payment type"
            choices={APP_CONFIG.ORDERS.FILTERS.payment_type}
            alwaysOn
        />
        <SelectInput
            source="payment_status"
            placeholder="Payment status"
            choices={APP_CONFIG.BRANDPALS.payment_status}
            alwaysOn
        />
        <TextInput
            source="payer_email_address"
            placeholder="Payer email address"
            type="text"
            alwaysOn
        />
        <DateInput source="from_date" label="From Date" />
        <DateInput source="to_date" label="End Date" />
    </Filter>
);

const ButtonOrder = (props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            data-id={props?.record.id}
            onClick={props?.onClick}
        >
            Order
        </Button>
    );
};
const ButtonSubscription = (props) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            data-id={props?.record.id}
            onClick={props?.onClick}
        >
            Subscription
        </Button>
    );
};

const OrderSummaryList = (props) => {
    const redirect = useRedirect();
    const classes = useStyles();
    const handleClickOrder = (e) => {
        const { id } = e.currentTarget.dataset;
        redirect(
            `/orders/?filter=%7B"order_summary_ids"%3A"${id}"%7D&order=desc&page=1&perPage=10&sort=order_by`,
        );
    };
    const handleClickSubcription = (e) => {
        const { id } = e.currentTarget.dataset;
        console.log(e);
        redirect(
            `/subscription?filter=%7B"order_summary_id"%3A"${id}"%7D&order=desc&page=1&perPage=10&sort=order_by`,
        );
    };
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="List of orders summary"
            filters={<PostFilter />}
            sort={{ field: 'order_by', order: 'desc' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="order_number" label="Order Number" />
                <TextField source="desc" label="Description" />
                <NumberField source="total" label="Total" />
                <TextField source="brand_type" label="Brand type" />
                <TextField source="payment_type" label="Payment type" />
                <TextField source="payment_id" label="Payment ID" />
                <TextField source="invoice_id" label="Invoice ID" />
                <TextField source="payment_status" label="Payment status" />
                <TextField
                    source="payer_email_address"
                    label="Payer email address"
                />
                <BooleanField
                    source="sent_completed_email"
                    label="Sent completed email"
                />
                <TextField source="channel" label="Channel" />
                <NumberField source="abandon_emails" label="Abandon emails" />
                <TextField source="domain" label="Domain" />
                <TextField source="coupon_code" label="Coupon code" />
                <NumberField source="origin_total" label="Origin total" />
                <NumberField source="email_flag" label="Email flag" />
                <NumberField source="new_posts" label="New posts" />
                <NumberField source="current_posts" label="Current posts" />
                <TextField source="package" label="Package" />
                <TextField source="id" label="ID" />
                <BooleanField source="is_signed_up" label="Is Signed Up" />
                <DateField source="created_at" label="Created At" />
                <ButtonOrder onClick={handleClickOrder} />
                <ButtonSubscription onClick={handleClickSubcription} />
            </Datagrid>
        </List>
    );
};

export default OrderSummaryList;
