import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    useNotify,
    NumberInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    Toolbar,
    SaveButton,
} from 'react-admin';

import APP_CONFIG from 'constants/APP_CONFIG';

const UserEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton disableRipple />
    </Toolbar>
);

const BrandPalsEdit = (props) => {
    const notify = useNotify();
    const onFailure = (error) => {
        notify(error, 'error');
    };

    return (
        <Edit
            {...props}
            title="Edit Order"
            onFailure={onFailure}
            undoable={false}
        >
            <SimpleForm toolbar={<UserEditToolbar />}>
                <SelectInput
                    source="status"
                    choices={APP_CONFIG.ORDERS.FILTERS.smm_status}
                />
                <SelectInput
                    source="payment_status"
                    choices={APP_CONFIG.BRANDPALS.payment_status}
                />
                <TextInput source="payment_id" label="Payment ID" />
                <NumberInput source="panel_id" label="Panel ID" />
                <NumberInput source="panel_price" label="Panel Price" />
                <TextInput source="site_url" label="Site URL" disabled />
                <TextInput source="fullname" label="Full name" disabled />
                <TextInput source="email" label="Email" disabled />
                <NumberInput source="price" label="Price" disabled />
                <TextInput source="domain" label="Domain" disabled />
                <TextInput
                    source="payment_type"
                    label="Payment Type"
                    disabled
                />
                <SelectInput
                    source="plan"
                    choices={APP_CONFIG.BRANDPALS.plan}
                    disabled
                />
                <BooleanInput
                    source="is_alternative"
                    label="Is Alternative"
                    disabled
                />
                <TextInput
                    source="order_number"
                    label="Order Number"
                    disabled
                />
                <NumberInput source="row_id" label="Row ID" disabled />
                <TextInput source="id" label="ID" disabled />
                <p style={{ fontWeight: 'bold' }}>File: </p>
                <ArrayInput source="files">
                    <SimpleFormIterator inline disableAdd disableRemove>
                        <TextInput source="id" disabled label="ID" />
                        <TextInput source="name" disabled label="Name" />
                        <TextInput
                            source="mime_type"
                            disabled
                            label="Mime type"
                        />
                        <TextInput source="url" disabled label="URL" />
                    </SimpleFormIterator>
                </ArrayInput>
                <p style={{ fontWeight: 'bold' }}>Contact: </p>
                <TextInput
                    source="contact.brand_name"
                    label="Brand name"
                    disabled
                />
                <TextInput
                    source="contact.fullname"
                    label="Fullname"
                    disabled
                />
                <TextInput source="contact.country" label="Country" disabled />
                <TextInput source="contact.address" label="Address" disabled />
                <TextInput source="contact.phone" label="Phone" disabled />
                <p style={{ fontWeight: 'bold' }}>Content: </p>
                <TextInput
                    source="content.article_topic"
                    label="Article topic"
                    disabled
                />
                <TextInput source="content.keyword" label="Keyword" disabled />
                <TextInput source="content.note" label="Note" disabled />
            </SimpleForm>
        </Edit>
    );
};

export default BrandPalsEdit;
