import List from './List';
import BrandCreate from './Create';
import BrandEdit from './Edit';

const brand = {
    list: List,
    create: BrandCreate,
    edit: BrandEdit,
};

export default brand;
