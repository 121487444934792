import { DOMAINS } from 'constants/APP_CONFIG';
import { Create, SimpleForm, SelectInput, TextInput } from 'react-admin';

const CouponCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="account" />
            <SelectInput source="domain" defaultValue="" choices={DOMAINS} />
        </SimpleForm>
    </Create>
);

export default CouponCreate;
