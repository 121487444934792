import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    Filter,
    SelectInput,
    EditButton,
    TextInput,
    BooleanField,
    DeleteWithConfirmButton,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import APP_CONFIG from 'constants/APP_CONFIG';

const useStyles = makeStyles({
    row: {
        'white-space': 'nowrap',
    },
    header: {
        background: '#ccc',
        'white-space': 'nowrap',
    },
});

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="id" placeholder="ID" type="text" alwaysOn />
        <TextInput
            source="code"
            placeholder="Enter code"
            type="text"
            alwaysOn
        />
        <SelectInput
            source="brand_type"
            label="Brand"
            choices={APP_CONFIG.ORDERS.FILTERS.brand_type}
            alwaysOn
        />
    </Filter>
);

const Type = (props) => {
    return props.children === 'percent' ? '%' : '$';
};

const OrderList = (props) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            bulkActionButtons={false}
            title="List of orders"
            filters={<PostFilter />}
            sort={{ field: 'order_by', order: 'desc' }}
            hasEdit
        >
            <Datagrid
                classes={{ headerCell: classes.header, row: classes.row }}
            >
                <TextField source="id" label="ID" />
                <TextField source="brand_type" label="Brand Type" />
                <TextField source="domain" label="Domain" />
                <TextField source="code" label="CODE" />
                <TextField source="name" label="Name" />
                <NumberField source="value" label="Value" />
                <TextField source="type" label="Type" component={Type} />
                <NumberField source="counter" label="Count" />
                <NumberField
                    source="counter_applied"
                    label="Number of codes used"
                />
                <NumberField
                    source="limit_times"
                    label="Limit Times"
                    defaultValue="None"
                />
                <DateField
                    showTime
                    source="limit_start_date"
                    label="Start Date"
                />
                <DateField showTime source="limit_end_date" label="End Date" />
                <DateField showTime source="created_at" label="Created at" />
                <DateField showTime source="updated_at" label="Updated at" />
                <BooleanField source="enable" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};

export default OrderList;
